import "./style.css";
import "./styles/vuetifyOverrides.scss";
import * as Sentry from "@sentry/vue";
import clerk, { CLERK_INJECTION_KEY } from "./plugins/clerk";
import { CONVEX_INJECTION_KEY, createConvexVue } from "@convex-vue/core";
import { createApp, inject } from "vue";
import AppWrapper from "./AppWrapper.vue";
import { Doc } from "../convex/_generated/dataModel";
import { api } from "../convex/_generated/api";
import { getStoredGuardian } from "./userHelper";
import { isIosApp } from "./util";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { Resources } from "@clerk/types";
import { initSession } from "@/session";
import { catastrophicErrors } from "./components/auth";

(window as any).heartbeat = () => {
  if (catastrophicErrors.length > 0) {
    console.log("catastrophicErrors", catastrophicErrors);
    return false;
  }
  return true;
};
export const app = createApp(AppWrapper);

const hasUpgraded = localStorage.getItem("upgraded");
function upgradeInstall() {
  if (hasUpgraded == null) {
    if (isIosApp()) {
      // For native iPadOS app
      (window as any).webkit.messageHandlers.setWonderLoginURL.postMessage(
        `https://${window.location.host}/use-family`,
      );
    }
    localStorage.setItem("upgraded", "true");
  }
}

// Parents created after this time must complete First Use before using the app. Setting this to
// `null` disabled First Use entirely.
const FIRST_USE_REQUIRED_AFTER_CREATION_TIME_MS = 1709309079535; // 2024-03-01

let replaySamplingRate = 0.01;

// if the current URL includes the word LOTL, then we are in a LOTL session and we should sample 100% of the sessions
if (window.location.href.includes("LOTL")) {
  replaySamplingRate = 1.0;
}

const sentryFeedback = new Sentry.Feedback({
  autoInject: false,
  buttonLabel: "",
  colorScheme: "dark",
  formTitle: "Share Ideas / Report Issue",
  showEmail: false,
  showBranding: false,
  showName: false,
  submitButtonLabel: "Submit",
});

/**
 * Auto-inject a Sentry feedback widget after 3 seconds in case Wonder code throws an exception and
 * the feedback button should be accessible. If the Wonder app successfully mounts it is able to
 * clear the timeout and prevent injection.
 *
 * @see https://linear.app/hello-wonder/issue/WON-615/eliminate-flash-of-sentrys-bullhorn-icon
 */
function injectSentryFeedback() {
  sentryFeedback.createWidget({ autoInject: true });
}
const sentryFeedbackTimeout = setTimeout(injectSentryFeedback, 3_000);

Sentry.init({
  app,
  dsn: "https://ddc9aa1c5f6fb3329d3a4345b666e237@o4506211663806464.ingest.sentry.io/4506211666493440",
  initialScope: {
    tags: {
      isIOS: isIosApp(),
    },
  },
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
    sentryFeedback,
  ],
  // Session Replay
  replaysSessionSampleRate: replaySamplingRate, // Sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
});

// Inject Sentry so descendant components can control it.
// * Use `inject('sentryFeedback')` to get a reference
// * Call `sentryFeedback.remove()` to remove the button and its widget
// * Call `clearTimeout(sentryFeedbackTimeout)` to cancel injecting a new widget
app.provide("sentryFeedback", {
  sentryFeedback,
  sentryFeedbackTimeout,
});

export const AUTH_INJECTION_KEY = Symbol("auth") as InjectionKey<{
  isAuthenticated: Ref<boolean>;
  session: Ref<Resources["session"]>;
}>;

const clerkSession = ref();
const convexIsAutheticated = ref(false);
app.provide(AUTH_INJECTION_KEY, {
  isAuthenticated: convexIsAutheticated,
  session: clerkSession,
});

const convexVue = createConvexVue({ convexUrl: import.meta.env.VITE_CONVEX_URL });
app.use(clerk);
app.use(convexVue);
app.use(vuetify);
app.use(router);

app.runWithContext(() => {
  const clerkInject = inject(CLERK_INJECTION_KEY);
  const convexClient = inject(CONVEX_INJECTION_KEY);
  if (clerkInject == null || convexClient == null) throw new Error("MISSING SOME CLIENTS!");

  clerkInject.clerkClient.clerk.addListener(({ session }) => {
    clerkSession.value = session;

    if (session == null) {
      convexIsAutheticated.value = false;
      convexClient.client.clearAuth();
    } else {
      convexClient.setAuth(
        () =>
          session.getToken({
            skipCache: true,
            template: "convex",
          }),
        (isAuthenticated) => {
          convexIsAutheticated.value = isAuthenticated;
          clerkInject.clerkClient.processClerk();
        },
      );
    }
  });
});

function userRequiresFirstUse(user: Doc<"users">): boolean {
  return (
    FIRST_USE_REQUIRED_AFTER_CREATION_TIME_MS != null &&
    user.isParent &&
    user._creationTime >= FIRST_USE_REQUIRED_AFTER_CREATION_TIME_MS &&
    (user.termsOfServiceAcceptedAt == null || user.privacyPolicyAcceptedAt == null)
  );
}

app.runWithContext(() => {
  const clerkInject = inject(CLERK_INJECTION_KEY);
  if (clerkInject == null) throw new Error("MISSING CLERK CLIENT!");

  const convexClient = inject(CONVEX_INJECTION_KEY);
  if (convexClient == null) throw new Error("MISSING CONVEX CLIENT!");

  function getCurrentUser() {
    if (convexClient == null || clerkInject?.clerkClient.clerk.session == null) return null;
    return convexClient.query(api.queries.getConvexUsers.byClerkId, {
      clerkId: clerkInject.clerkClient.clerk.session.user.id,
    });
  }

  // Route "meta"
  //
  // auth
  // * "disallowed" - Routes that are only visible when logged out
  //   * if logged in user, redirect to home
  // * "required" - Routes that are only visible when logged in
  //   * if logged out user, reddirect to home
  //   * else if logged in user && next route is First Use (flow=true) && user has completed First
  //     Use, redirect home
  //   * else if logged in user && next route is _not_ First Use (flow=true) && user has _not_
  //     completed First Use, redirect to First Use
  // * default - Routes with no defined auth state
  //   * if logged in user && next route is _not_ First Use (flow=true) && user has _not_ completed
  //     First Use, redirect to First Use
  //
  // isFirstUseFlow
  // * true - route is part of "First Use" flow
  router.beforeResolve(async (to) => {
    if (!to.path.startsWith("/ipadLOTL") && !to.path.startsWith("/family/")) {
      upgradeInstall();
    }

    if (to.path === "/signout") {
      // '/signout' always accessible, always able to end session
      return;
    } else if (to.meta.auth === "disallowed") {
      await clerkInject.clerkClient.clerk.load();
      if (clerkInject.clerkClient.clerk.session) {
        // auth is disallowed, but session exists
        // -> redirect to home
        return { path: "/", replace: true };
      }
    } else if (to.meta.auth === "required") {
      await clerkInject.clerkClient.clerk.load();
      if (!clerkInject.clerkClient.clerk.session) {
        // auth required, but no session
        // -> redirect to home
        return { path: "/", replace: true };
      }

      await clerkInject.clerkClient.processClerk();
      const currentUser = await getCurrentUser();
      if (currentUser == null) {
        // auth required, but no user
        // -> redirect to home
        return { path: "/", replace: true };
      }

      if (to.meta.isFirstUseFlow) {
        if (!userRequiresFirstUse(currentUser)) {
          // next route is "flow" && auth is required && session exists && First Use complete
          // -> redirect to home
          return { path: "/", replace: true };
        }
      } else {
        if (userRequiresFirstUse(currentUser)) {
          // next route _not_ "flow" && auth is required && session exists, but First Use incomplete
          // -> redirect to First Use
          return { path: "/flow/guardian", replace: true };
        }
      }
    } else {
      await clerkInject.clerkClient.clerk.load();
      if (clerkInject.clerkClient.clerk.session) {
        await clerkInject.clerkClient.processClerk();
        const currentUser = await getCurrentUser();
        if (currentUser) {
          const clerkSessionId = clerkInject.clerkClient.clerk.session.id;
          const userId = currentUser._id;
          const sessionId = await initSession(clerkSessionId, userId);
          console.log(`+++++ sessionId: ${sessionId}, clerkSessionId: ${clerkSessionId}, userId: ${userId}`);
        }
        if (currentUser && !to.meta.isFirstUseFlow) {
          if (userRequiresFirstUse(currentUser)) {
            // next route _not_ "flow" && session exists, but First Use incomplete
            // -> redirect to First Use
            return { path: "/flow/guardian", replace: true };
          } else if (to.query["from"] === "signin" || to.query["from"] === "signup") {
            // coming from a fresh signin or signup && session exists && First Use complete
            // -> redirect to parent/child selection page
            return { path: "/flow/who", replace: true };
          }
        }
      } else if (to.meta.auth === "mixed" && getStoredGuardian() != null) {
        // Not auth'd, viewing "mixed" auth page where auth alters the view, and Guardian in storage
        // -> redirect to FamilyPicker to render the Guardian's family
        return { path: "/family", replace: true };
      }
    }
  });
});

app.mount("#app");

// Polyfill findlast
if (!Array.prototype.findLast) {
  Array.prototype.findLast = function <T>(callback: (arg0: T, arg1: number, arg2: T[]) => T) {
    if (this == null) {
      throw new TypeError("this is null or not defined");
    }
    const arr = Object(this);
    const len = arr.length >>> 0;
    for (let i = len - 1; i >= 0; i--) {
      if (callback(arr[i], i, arr)) {
        return arr[i];
      }
    }
    return undefined;
  };
}

// Polyfill .at
if (!Array.prototype.at) {
  Array.prototype.at = function <T>(this: T[], index: number): T | undefined {
    // If the index is negative, calculate the index from the end of the array
    if (index < 0) {
      index = this.length + index;
    }
    // Return the element at the computed index or undefined if out of bounds
    return this[index];
  };
}
