import * as Sentry from "@sentry/vue";
import { getConvexUserForClerkId, httpSetAuth } from "../convexUserHelpers";
import Clerk from "@clerk/clerk-js";
import { Doc } from "../../convex/_generated/dataModel";
import { clearAuth, createMutation, setAuth, useAction } from "../convexvue";
import { retryWithBackoff } from "../../wonderSearchEngine/helpers";
import { mixpanel } from "../wondermixpanel";
import { api } from "../../convex/_generated/api";
import router from "../router";
import { initSession } from "@/session";
import { isIosApp } from "@/util";

// store catastrophic errors in clerk

export const catastrophicErrors: string[] = [];
export class ClerkClient {
  clerk: Clerk;
  _authChecked = false;

  constructor(key: string) {
    this.clerk = new Clerk(key);
  }

  checkAuth(): boolean {
    if (this._authChecked) return false;
    this._authChecked = true;

    if (this.clerk.session) {
      this.processClerk();
      return true;
    } else {
      if (window.location.pathname == "/dashboard") {
        window.location.href = window.location.protocol + "//" + window.location.host;
      }
      return false;
    }
  }

  becomeUser(convexUser: Doc<"users">) {
    Sentry.setUser({ username: convexUser.first_name, email: convexUser.email || convexUser.clerkid });

    mixpanel.identify(convexUser._id.toString());
    mixpanel.track("Authed");

    if (convexUser.isParent && convexUser.email != null) {
      // Store last authenticated parent so they can log back in from the Family Selector
      localStorage.setItem(
        "parent",
        JSON.stringify({
          _id: convexUser._id,
          avatar: convexUser.avatar,
          email: convexUser.email,
          first_name: convexUser.first_name,
          isKid: convexUser.isKid,
          isParent: convexUser.isParent,
        }),
      );
    }

    try {
      if ("chrome" in window && chrome.runtime) {
        const editorExtensionId = "ghpfnokmgncaakihljmphcpdkiedfhjf";
        chrome.runtime.sendMessage(editorExtensionId, { convexUserId: convexUser._id }, function (response) {
          console.log(response);
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  async fastSignInWithSecret(secret: string) {
    const ticketResponse = await retryWithBackoff(async () => {
      const ticketResponseOrError = await useAction(api.actions.makeLoginURLFromSecrets.makeLoginURL, {
        secret,
      })();

      // Throw error when bad response from Clerk so the `makeLoginURL` call will be retried.
      if (ticketResponseOrError == null || "errors" in ticketResponseOrError) throw new Error("Invalid Clerk response");
      return ticketResponseOrError;
    }).catch((e) => {
      console.error("Error signing in with secret", e);
      throw e;
    });

    // Sign current user out before attempting to sign in the next one
    await this.signOut(false);

    await retryWithBackoff(async () => {
      console.log("result", ticketResponse);
      const signIn = await this.clerk.client?.signIn.create({ strategy: "ticket", ticket: ticketResponse.token });
      console.log("session", signIn);

      // Throw error to force the `signIn.create` to be retried.
      if (signIn == null) throw new Error("Invalid Clerk signIn");

      await this.clerk.setSession(signIn.createdSessionId);
      console.log(this.clerk.session);
    });

    return this.processClerk();
  }

  mountSignup(node: HTMLDivElement) {
    this.clerk.mountSignUp(node, {
      appearance: {},
      redirectUrl: window.location.href.toString(),
    });
  }

  processClerk() {
    return retryWithBackoff(async () => {
      const session = this.clerk.session;
      if (!session) {
        clearAuth();
        return;
      }

      const token = await session.getToken({
        skipCache: true,
        template: "convex",
      });

      if (token) {
        setAuth(
          () => {
            return Promise.resolve(token);
          },
          (authenticated) => {
            if (authenticated) {
              // if this is a first time signon, we need to attach the adult to the kid we have created already
              // get "signon" from localstorage
              const signon = localStorage.getItem("signon");
              if (signon) {
                console.log("found a signon", signon);
                createMutation(api.simplesignons.signons.addAdultForSignon, {
                  signOnSecret: signon,
                })().then(() => {
                  localStorage.removeItem("signon");
                  console.log("removed signon");
                });
              }
            }
          },
        );
        httpSetAuth(token);
      } else {
        console.error("no convex token from clerk");
      }

      mixpanel.track("Authed", {
        Method: "clerkticket",
      });

      try {
        const convexUser = await getConvexUserForClerkId(session.user.id);
        if (convexUser) {
          const clerkSessionId = session.id;
          const userId = convexUser._id;
          const sessionId = await initSession(clerkSessionId, userId);
          console.log(`..... sessionId: ${sessionId}, clerkSessionId: ${clerkSessionId}, userId: ${userId}`);
          this.becomeUser(convexUser);
        } else {
          throw new Error("No Convex user");
        }
      } catch (error) {
        throw new Error("No Convex user");
      }

      const invitation = window.location.search.split("?tfa=")[1];
      if (invitation) {
        console.log("found invitation", invitation);
        const invitationId = invitation.split("&")[0].split("#")[0];

        // accept invitation
        await createMutation(api.invitations.index.acceptInvitation, { id: invitationId })();

        // remove invitation from url
        window.history.replaceState({}, document.title, window.location.pathname);
      }
    }).catch((e) => {
      console.error("Error processing Clerk", e);
      catastrophicErrors.push(e.message);

      throw e;
    });
  }

  signInWithSecret(secret: string) {
    return retryWithBackoff(async () => {
      const ticketResponse = await useAction(api.actions.makeLoginURLFromSecrets.makeLoginURL, {
        secret,
      })();

      // Throw error when bad response from Clerk so the `makeLoginURL` call will be retried.
      if (ticketResponse == null || "errors" in ticketResponse) throw new Error("Invalid Clerk response");
      return ticketResponse;
    }).then((ticketResponse) => {
      this.clerk.signOut().then(() => {
        console.log(ticketResponse);
        this.clerk.client?.signIn.create({ strategy: "ticket", ticket: ticketResponse.token }).then((signin) => {
          console.log("session", signin);
          // clerk.session = session;
          this.clerk.setSession(signin.createdSessionId).then(() => {
            console.log(this.clerk.session);
            this.processClerk().then(() => {
              router.push("/");
            });
          });
          signin.createdSessionId;
        });
        console.log("result", ticketResponse);
      });
    });
  }

  async removeAccountFromDevice() {
    // `false` to prevent navigation
    await this.signOut(false);

    // Clear stored items related to login such as phone number and guardian information to reset
    // the state of the user's browser for a new login to begin.
    localStorage.clear();

    // Done clearing device, head back home.
    window.location.href = "/";
  }

  async signOutToFamily() {
    await this.signOut("/family/");
    if (this.clerk.session) {
      await this.clerk.signOut();
    }

    if (isIosApp()) {
      (window as any).webkit.messageHandlers.setWonderDevURL.postMessage(`https://${window.location.host}/use-family`);
    } else {
      window.location.href = "/use-family";
    }
  }

  async signOut(newPath?: string | false) {
    if (this.clerk.session) {
      await this.clerk.signOut();
    }

    // return if newPath is false
    if (newPath !== false) {
      if (newPath && newPath != window.location.href) {
        window.location.pathname = newPath;
      } else {
        window.location.reload();
      }
    }
  }
}
