import { Doc } from "../convex/_generated/dataModel";

// A "user" document with only select fields. This is stored in `localStorage` in the user's
// browser/device to enable child <-> parent switching.
type PartialUser = Pick<Doc<"users">, "_id" | "avatar" | "email" | "first_name" | "isKid" | "isParent">;

export function getStoredGuardian(): PartialUser | null {
  const guardianJson = localStorage.getItem("parent");
  let guardian: PartialUser | null;
  if (guardianJson == null) {
    guardian = null;
  } else {
    try {
      guardian = {
        // added 2024-03-08 - localStorage before then did not include `isKid` or `isParent`. this
        // is used by `<GuardianPicker>` for styling but does not affect authentication so is safe
        // to hard-code here. can eventually be removed after existing sessions dead, maybe 2mo?
        isKid: false,
        isParent: true,
        ...JSON.parse(guardianJson),
      };
    } catch (_error) {
      guardian = null;
    }
  }
  return guardian;
}
export function setDevicePrimaryUserType(type: "kid" | "guardian") {
  localStorage.setItem("devicePrimaryUserType", type);
}
export function getDevicePrimaryUserType(): "kid" | "guardian" {
  return "guardian";
  const stored = localStorage.getItem("devicePrimaryUserType") as "kid" | "guardian";
  return stored || "guardian";
}
