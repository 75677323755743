import { type FunctionReference, getFunctionName, type OptionalRestArgs } from "convex/server";
import { type Ref, ref } from "vue";
import { BaseConvexClient } from "convex/browser";
import type { QueryToken } from "convex/browser";
import type { Value } from "convex/values";

export type AuthTokenFetcher = (args: { forceRefreshToken: boolean }) => Promise<string | null | undefined>;

export class ConvexSolidClient {
  client: BaseConvexClient;
  // List of createQuery() calls below, that represent current subscriptions
  // to convex queries.
  // [setter from solid, path of query -- 'getCounter', arguments to query, unsubscribe from convex]
  subsArray: Map<
    QueryToken,
    Map<string, [(val: any, queryToken: string) => any, string, Record<string, Value> | undefined, () => void]>
  >;
  constructor() {
    // origin = origin ?? convexConfig.origin;
    this.subsArray = new Map();
    const address = import.meta.env.VITE_CONVEX_URL;
    this.client = new BaseConvexClient(address, (vals) => {
      this.handleNewValues(vals);
    });
  }

  // Called by convex internal client every time some of our subscriptions
  // have new values.
  handleNewValues(updatedQueries: QueryToken[]) {
    for (const queryToken of updatedQueries) {
      const queryInfos = this.subsArray.get(queryToken);
      // const queryInfo = this.subs.get(queryToken);
      if (queryInfos !== undefined) {
        for (const queryInfo of queryInfos.values()) {
          const [setter, queryPath, args] = queryInfo;
          const newValue = this.client.localQueryResult(queryPath, args);
          setter(newValue, queryToken);
        }
      }
    }
  }

  addSub<Name extends FunctionReference<"query", "public">>(
    randomId: string,
    setter: (val: any, token: string) => any,
    functionName: Name,
    ...args: OptionalRestArgs<Name>
  ): string {
    const stringFunctionName = getFunctionName(functionName);
    const { queryToken, unsubscribe } = this.client.subscribe(stringFunctionName, args[0] || {});
    const existingVal = this.client.localQueryResult(stringFunctionName, args[0] || {});
    setter(existingVal, queryToken);
    if (!this.subsArray.has(queryToken)) {
      this.subsArray.set(queryToken, new Map());
    }

    this.subsArray.get(queryToken)!.set(randomId, [setter, stringFunctionName, args[0] || {}, unsubscribe]);

    // this.subs.set(queryToken, [setter, queryPath, args, unsubscribe]);
    return queryToken;
  }

  rmSub(randomString: string, queryToken: string) {
    const queryInfos = this.subsArray.get(queryToken);
    if (queryInfos !== undefined) {
      const queryInfo = queryInfos.get(randomString);
      const [, , , unsubscribe] = queryInfo!;
      unsubscribe();
      queryInfos.delete(queryToken);
    }
  }

  mutate(queryPath: string, args?: Record<string, Value>): Promise<any> {
    return this.client.mutation(queryPath, args);
  }
}

// given as an instance of ConvexSolidClient.
export const ConvexContext: Ref<ConvexSolidClient | undefined> = ref();

export function clearAuth() {
  const convex = ConvexContext.value;
  if (convex === undefined) {
    throw "No convex context";
  }
  convex.client.clearAuth();
}

export function setAuth(auth: AuthTokenFetcher, onChange: (isAuthenticated: boolean) => void | undefined) {
  const convex = ConvexContext.value;
  if (convex === undefined) {
    throw "No convex context";
  }
  convex.client.setAuth(auth, onChange);
}

export function useAction<Name extends FunctionReference<"action">>(
  actionName: Name,
  ...args: OptionalRestArgs<Name>
): () => Promise<Name["_returnType"] | undefined> {
  const convex = ConvexContext.value;
  if (convex === undefined) {
    throw "No convex context";
  }
  const fullArgs = args;
  return () => {
    const actionNameString = getFunctionName(actionName);
    return convex.client.action(actionNameString, ...fullArgs);
  };
}

export function queryCallback<Name extends FunctionReference<"query", "public">>(
  name: Name,
  once: boolean,
  callback: (value: Name["_returnType"] | undefined) => void,
  ...args: OptionalRestArgs<Name>
): void {
  const convex = ConvexContext.value;
  if (convex === undefined) {
    throw "No convex context";
  }
  const randomString = Math.random().toString(36).substring(7);

  function setter(value: unknown, queryToken: string) {
    callback(value);
    if (once) convex?.rmSub(randomString, queryToken);
  }
  convex!.addSub(randomString, setter, name, ...args);
  return;
}

export function createMutation<Name extends FunctionReference<"mutation">>(
  queryPath: Name,
  ...args: OptionalRestArgs<Name>
) {
  const convex = ConvexContext.value;
  if (convex === undefined) {
    throw "No convex context";
  }
  const fullArgs = args;

  return () => {
    const stringFunctionName = getFunctionName(queryPath);
    return convex.mutate(stringFunctionName, fullArgs[0] || {});
  };
}

export function init() {
  if (!ConvexContext.value) ConvexContext.value = new ConvexSolidClient();
}
init();
