export type URLChecker = (url: URL, siteURL: URL) => boolean;

export function rootDomain(url: URL): string {
  const parts = url.hostname.split(".");
  return parts.slice(parts.length - 2).join(".");
}
export function parallelize(fn: () => unknown) {
  for (let i = 0; i < 5; i++) {
    (async () => {
      await fn();
    })();
  }
}

export async function retryFetchWithBackoff(url: URL | string, retries: number = 0): Promise<string> {
  try {
    const r = await fetch(url);
    return await r.text();
  } catch (e) {
    if (retries > 3) {
      console.log("giving up on url", url, "because of", e);
      return "";
    }
    const backoff = Math.pow(2, retries) * 1000 + Math.random() * 1000;
    console.log("retrying after ", url.toString(), backoff);
    await new Promise((resolve) => setTimeout(resolve, backoff));
    return await retryFetchWithBackoff(url, retries + 1);
  }
}

export type NormalizedURL = string;
export function normalizeURL(url: URL | string): NormalizedURL {
  const normal = url.toString();
  const normalWithoutProtocol = normal.replace(/^https?:\/\//, "");
  const normalWithoutProtocolAndTrailingSlash = normalWithoutProtocol.replace(/\/$/, "");
  const normalWithoutHash = normalWithoutProtocolAndTrailingSlash.replace(/#.*$/, "");
  return normalWithoutHash;
}

export function retryWithBackoff<T>(fn: () => Promise<T>, retries: number = 0): Promise<T> {
  return fn().catch((e) => {
    if (retries > 3) {
      console.log("giving up on retry", e);
      return Promise.reject();
    }
    const backoff = Math.pow(2, retries) * 1000 + Math.random() * 1000;
    console.log("retrying after ", e, backoff);
    return new Promise((resolve) => setTimeout(() => resolve(retryWithBackoff(fn, retries + 1)), backoff));
  });
}
