import { customMutation, customQuery } from "convex-helpers/server/customFunctions";
import { mutation, query } from "../_generated/server";
import type { DataModel } from "../_generated/dataModel";
import { GenericQueryCtx } from "convex/server";
import { Doc } from "../_generated/dataModel";

export const defaultUserPermissions: NonNullable<Doc<"users">["permissions"]> = {
  browsing: false,
  games: true,
  images: true,
  sesameVideos: false,
  sites: false,
  videos: true,
};

async function getUserFromCtx(ctx: GenericQueryCtx<DataModel>) {
  const identity = await ctx.auth.getUserIdentity();
  if (!identity) {
    throw new Error("Unauthenticated call to function requiring authentication");
  }

  const clerkId = identity.tokenIdentifier.split("|")[1];
  const user = await ctx.db
    .query("users")
    .withIndex("by_clerk_id", (q) => q.eq("clerkid", clerkId))
    .unique();

  if (!user) throw new Error("User not found " + clerkId);
  return user;
}

export const mutationWithAdmin = customMutation(mutation, {
  args: {},
  input: async (ctx) => {
    const user = await getUserFromCtx(ctx);
    if (!user.isAdministrator) throw new Error("User is not an admin " + user.clerkid);
    return { args: {}, ctx: { user } };
  },
});

export const mutationWithUser = customMutation(mutation, {
  args: {},
  input: async (ctx) => {
    const user = await getUserFromCtx(ctx);
    return { args: {}, ctx: { user } };
  },
});

export const queryWithAdmin = customQuery(query, {
  args: {},
  input: async (ctx) => {
    const user = await getUserFromCtx(ctx);
    if (!user.isAdministrator) throw new Error("User is not an admin " + user.clerkid);
    return { args: {}, ctx: { user } };
  },
});

export const queryWithUser = customQuery(query, {
  args: {},
  input: async (ctx) => {
    const user = await getUserFromCtx(ctx);
    return { args: {}, ctx: { user } };
  },
});
