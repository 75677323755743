import { api } from "../convex/_generated/api";
import { Doc, Id } from "../convex/_generated/dataModel";
import { ConvexHttpClient } from "convex/browser";
import { PermissionKey } from "convex/schema";
import { defaultUserPermissions } from "../convex/users/userHelpers";

const convex = new ConvexHttpClient(import.meta.env.VITE_CONVEX_URL);

export function getConvexUserForClerkId(clerkId: string): Promise<Doc<"users"> | null> {
  return convex.query(api.queries.getConvexUsers.byClerkId, { clerkId });
}

export function getConvexUserForConvexId(convexUserId: Id<"users">): Promise<Doc<"users"> | null> {
  return convex.query(api.queries.getConvexUsers.byUserId, {
    convexUserId,
  });
}

export function httpSetAuth(auth: string) {
  convex.setAuth(auth);
}

type KeysMatching<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

export function getAllNamedPermissions() {
  return Object.keys(defaultUserPermissions) as KeysMatching<
    NonNullable<Doc<"users">["permissions"]>,
    boolean
  >[] as PermissionKey[];
}

export function getPermission(
  convexUser: Doc<"users">,
  permission: keyof NonNullable<Doc<"users">["permissions"]>,
): boolean {
  if (!convexUser.permissions || convexUser.permissions[permission] === undefined) {
    return defaultUserPermissions[permission]!;
  }

  return convexUser.permissions[permission] || false;
}
