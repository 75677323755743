import mixpanel from "mixpanel-browser";
let code = "2609100bd8eebeeec4fe4745ab520604";
if (window.location.hostname === "localhost") {
  code = "421e2da99d3c032eb341bafd96b9b0b2";
}
mixpanel.init(code, {
  debug: true,
  ignore_dnt: true,
  track_pageview: true,
  persistence: "cookie",
  cross_subdomain_cookie: true,
  cookie_domain: "hellowonder.ai",
});

export { mixpanel };
