import type { App, InjectionKey } from "vue";
import { Ref, ref } from "vue";
import { ClerkClient } from "../components/auth";

export const CLERK_INJECTION_KEY = Symbol("clerkClient") as InjectionKey<{
  clerkClient: ClerkClient;
  isClerkLoaded: Ref<boolean>;
}>;

export default {
  install: (app: App) => {
    const isClerkLoaded = ref(false);
    const publishableKey = import.meta.env.VITE_CLERK_PUBLIC_KEY;
    const clerkClient = new ClerkClient(publishableKey);

    app.config.globalProperties.$clerk = clerkClient.clerk;

    async function loadClerk() {
      await clerkClient.clerk.load();
      isClerkLoaded.value = true;
    }

    app.provide(CLERK_INJECTION_KEY, {
      clerkClient,
      isClerkLoaded,
    });

    loadClerk();
  },
};
