import { mixpanel } from "@/wondermixpanel";

// Returns `true` if the environment is the native iOS app.
//
// The iOS app exposes global message handlers for the web app to communicate with native code. If
// `setWonderLoginURL` exists then this is a `WKWebView` inside the native iOS app.
export function isIosApp(): boolean {
  return (window as any).webkit?.messageHandlers?.setWonderLoginURL != null;
}

export const profilingStartTimes: Map<string, number> = new Map();

export function startProfiling(timerID: string) {
  profilingStartTimes.set(timerID, performance.now());
}

export function trackElapsedTime(timerID: string, metricName: string) {
  const startTime = profilingStartTimes.get(timerID);
  if (!startTime) {
    console.error(`PROFILING: Timer ID "${timerID}" not found in profilingStartTimes`);
    return;
  }

  const delta = performance.now() - startTime;
  const formattedDelta = delta.toFixed(2);
  mixpanel.track(metricName, { elapsedTime: formattedDelta });
  console.log(
    'PROFILING: {"timerID": "' +
      timerID +
      '", "metricName": "' +
      metricName +
      '", "value": "' +
      formattedDelta +
      'ms"}',
  );
}
/** ===================  END - AUDIO DEBUGGING UTILS  =================== */
