import { api } from "../convex/_generated/api";
import { Id } from "../convex/_generated/dataModel";
import { createMutation } from "@/convexvue";

// TODO: raffy - refactor this file to use convex validators & use functions that require user context!

export const initSession = async (clerkSessionId: string, userId: Id<"users">): Promise<Id<"sessions">> => {
  const sessionId = await createMutation(api.session.initSession, { clerkSessionId, userId })();
  sessionStorage.setItem("SESSION_ID", sessionId);
  return sessionId;
};

export const getSessionId = (): Id<"sessions"> => {
  return sessionStorage.getItem("SESSION_ID") as Id<"sessions">;
};
